import {Inject, Injectable} from '@angular/core';
import {Observable, Observer, ReplaySubject} from 'rxjs';
import {map, take, takeUntil} from 'rxjs/operators';

import {AngularFirestore, DocumentChangeAction, QueryDocumentSnapshot,} from '@angular/fire/compat/firestore';
import {AngularFireStorage, AngularFireStorageReference} from '@angular/fire/compat/storage';

// @ts-ignore
import {environment} from '@env/environment';
import {ClientService} from '../../shared/_services/client.service';
import {PageService} from '../../shared/_services/page.service';
import {Base, loadObject} from '@nxt/model-core';

@Injectable()
export class FireService {
    get firestore() {
        return this.fs.firestore;
    }
    get loadAll() {
        return this.oSvc.getLoadAll(this.cSvc.name_key);
    }
    get olm() {
        return this.oSvc.getOLM(this.cSvc.name_key);
    }

    constructor(
        public fs: AngularFirestore,
        private s: AngularFireStorage,
        private cSvc: ClientService,
        private pSvc: PageService,
        @Inject('ObjectLoaderService') private oSvc: any
    ) {

        // Use local emulator when running locally.
        if (environment.type === 'local') {
            this.fs.firestore.useEmulator('localhost', 8080);
            this.s.storage.useEmulator("localhost", 9199);
        }
    }

    async exists(filePath: string): Promise<any> {
        let fileRef: AngularFireStorageReference = this.s.ref(filePath);
        let result: any = await fileRef.getDownloadURL().toPromise();
        return result;
    }

    // async delete(url: string) {
    //     try {
    //         if (url) {
    //             let parts: string[] = url.split('/');
    //             url = parts[parts.length - 1];
    //             if (url.indexOf('?')) {
    //                 url = url.substring(0, url.indexOf('?'));
    //             }
    //             console.log('i am the ? url',url)
    //             url = decodeURIComponent(url);
    //             let fileRef: AngularFireStorageReference = this.s.ref(url);
    //             await fileRef.delete();
    //         } else {
    //             console.warn(`No filePath passed. Cannot remove.`);
    //         }
    //     } catch (e) {
    //         console.warn(e);
    //     }
    // }

    async delete(url: string) {
        try {
            if (!url) {
                console.warn(`No URL passed. Cannot remove.`);
                return;
            }
            let storagePath;
            if (url.includes('.com/')) {
                const urlParts = url.split('.com/');
                storagePath = urlParts[urlParts.length - 1];
            } else {
                storagePath = url;
            }
            if (storagePath.includes('?')) {
                const urlParts = url.split('/o/');
                storagePath = urlParts[1];
                storagePath = storagePath.split('?')[0];
            }
            console.log(storagePath)
            storagePath = decodeURIComponent(storagePath);

            let fileRef: AngularFireStorageReference = this.s.ref(storagePath);
            await fileRef.delete();
        } catch (e) {
            console.warn('Error deleting file:', e);
        }
    }


    async upload(filePath: string, file: File): Promise<string> {
        return new Promise((resolve, reject) => {

            const ref = this.s.ref(filePath);
            const task = this.s.upload(filePath, file);

            // observe percentage changes
            // get notified when the download URL is available
            task.snapshotChanges()
                .subscribe(
                    async r => {
                        if (r.bytesTransferred === r.totalBytes) {
                            let downloadUrl: any = await ref.getDownloadURL().toPromise();
                            resolve(downloadUrl?.split(/\?/)[0] + '?alt=media');
                            this.pSvc.loading$.next(null);
                        }
                    },
                    err => {
                        this.pSvc.alert$.next(err);
                    }
                );

        });
    }

    _w: any = {};
    addW(id: string, path: string, time: number) {
        if (environment.type !== 'prod') {
            this._w[`${id}-${path}-${time}`] = `${id}-${path}`;
            // console.log(`${id}-${path} (+)`, Object.values(this._w));
            // console.log(Object.values(this._w).length);
        }
    }
    removeW(id: string, path: string, time: number) {
        if (environment.type !== 'prod') {
            delete this._w[`${id}-${path}-${time}`];
            // console.log(`${id}-${path} (-)`, Object.values(this._w));
            // console.log(Object.values(this._w).length);
        }
    }

    watchObject(
        path: string,
        destroy$?: ReplaySubject<boolean>,
        persistent?: boolean
    ): Observable<[Base,boolean]> {
        let now = Date.now();
        this.addW('wO',path,now);
        let o: Observable<[Base,boolean]> = new Observable((observer:Observer<[Base,boolean]>) => {
            let obj: Base;
            let id: string;
            let exists: boolean = false;
            let ref: any = this.fs.doc(path)
                .snapshotChanges()
                .pipe(map(res => res?.payload))
            if (destroy$) {
                ref = ref.pipe(takeUntil(destroy$));
            }
            if (!persistent) {
                ref = ref.pipe(takeUntil(this.pSvc.nav$))
            }
            if (this.cSvc.u$.getValue()) {
                ref = ref.pipe(takeUntil(this.cSvc.signedOut));
            }
            ref.subscribe(
                res => {
                    let newObj: Base = loadObject( res, {olm: this.olm});
                    let delta: boolean = (res?.id && (res?.id !== id || res.exists !== exists));
                    id = res.id
                    exists = res.exists;

                    if (!newObj) {
                        observer.next([res,delta]);
                    } else if (!newObj._exists) {
                        observer.next([newObj,delta]);
                    } else if (delta) {
                        observer.next([newObj,delta]);
                    } else if (JSON.stringify(obj) !== JSON.stringify(newObj)) {
                        observer.next([newObj,delta]);
                    }
                    this.pSvc.loading$.next(false);;
                    },
                e => {
                    if (e.toString().match(/code=permission-denied/)){
                        let m = e.toString().match(/Property (?<role>\S+) is undefined on object./);
                        this.pSvc.notification$.next({
                            title: 'Permission Denied',
                            message: `You do not have access to this content. Please contact an admin. Role required: ${m?.groups?.role||'?'}`
                        });
                    } else {
                        console.warn(path, e);
                    }
                    this.removeW('wO',path,now);
                },
                () => {
                    this.removeW('wO',path,now);
                }
            );
        });
        return o;
    }

    getColl(
        path: string,
        query?: IFirestoreQuery[],
        destroy$?: ReplaySubject<boolean>,
        persistent?: boolean,
    ): Observable<any[]> {
        // let now = Date.now();
        // this.addW('gC',path,now);
        // let o: Observable<[Base,boolean]> = new Observable((observer:Observer<any>) => {
            let ref: any = this.fs.collection(path, this.buildQuery(query))
                .get()
                .pipe(
                    map(res => res.docs.map((item: QueryDocumentSnapshot<any>) => item)),
                    take(1)
                )
            if (destroy$) {
                ref = ref.pipe(takeUntil(destroy$));
            }
            if (!persistent) {
                ref = ref.pipe(takeUntil(this.pSvc.nav$))
            }
            if (this.cSvc.u$.getValue()) {
                ref = ref.pipe(takeUntil(this.cSvc.signedOut))
            }
            return ref;
        //     ref.subscribe(
        //         res => {
        //             observer.next(res);
        //             this.pSvc.loading$.next(false);;
        //         },
        //         e => {
        //             console.warn(path, e);
        //             this.removeW('gC',path,now);
        //         },
        //         () => {
        //             this.removeW('gC',path,now);
        //         }
        //     );
        // });
        // return o;
    }

    watchColl(
        path: string,
        query?: IFirestoreQuery[],
        destroy$?: ReplaySubject<boolean>,
        persistent?: boolean
    ): Observable<any[]> {
        let now = Date.now();
        this.addW('wC',path,now);
        let o: Observable<[Base,boolean]> = new Observable((observer:Observer<any>) => {
            let ref: any = this.fs.collection(path, this.buildQuery(query))
                .snapshotChanges()
                .pipe(map(res => res.map((item: DocumentChangeAction<any>) => item.payload.doc)));
            if (destroy$) {
                ref = ref.pipe(takeUntil(destroy$));
            }
            if (!persistent) {
                ref = ref.pipe(takeUntil(this.pSvc.nav$))
            }
            if (this.cSvc.u$.getValue()) {
                ref = ref.pipe(takeUntil(this.cSvc.signedOut));
            }
            ref.subscribe(
                res => {
                    observer.next(res);
                    this.pSvc.loading$.next(false);;
                },
                e => {
                    if (e.toString().match(/code=permission-denied/)){
                        let m = e.toString().match(/Property (?<role>\S+) is undefined on object./);
                        this.pSvc.notification$.next({
                            title: 'Permission Denied',
                            message: `You do not have access to this content. Please contact an admin. Role required: ${m?.groups?.role||'?'}`
                        });
                    } else {
                        console.warn(path, e);
                    }
                    this.removeW('wC',path,now);
                },
                () => {
                    this.removeW('wC',path,now);
                }
            );
        });
        return o;
    }

    watchState(
        path: string,
        query?: IFirestoreQuery[],
        destroy$?: ReplaySubject<boolean>,
        persistent?: boolean
    ): Observable<any[]> {
        let now = Date.now();
        this.addW('wS',path,now);
        let o: Observable<[Base,boolean]> = new Observable((observer:Observer<any>) => {

            let ref: any = this.fs.collection(path, this.buildQuery(query))
                .stateChanges()
            if (destroy$) {
                ref = ref.pipe(takeUntil(destroy$));
            }
            if (!persistent) {
                ref = ref.pipe(takeUntil(this.pSvc.nav$));
            }
            if (this.cSvc.u$.getValue()) {
                ref = ref.pipe(takeUntil(this.cSvc.signedOut));
            }
            ref.subscribe(
                res => {
                    observer.next(res);
                    this.pSvc.loading$.next(false);;
                },
                e => {
                    console.warn(path, e);
                    this.removeW('wS',path,now);
                },
                () => {
                    this.removeW('wS',path,now);
                }
            );
        });
        return o;
    }

    async getObject(path: string): Promise<Base> {
        return new Promise((resolve,reject) => {
            this.getDoc(path)
                .subscribe({
                    next: doc => {
                        resolve(loadObject( doc, {olm: this.olm}));
                    },
                    error: e => {
                        console.warn(e, `getObject: ${path}`);
                        reject(e);
                    }
                });
        });
    }

    getDoc(path: string): Observable<any> {
        let o: Observable<[Base,boolean]> = new Observable((observer:Observer<any>) => {
            let ref: any = this.fs.doc(path).get().pipe(take(1));
            ref.subscribe(
                res => {
                    observer.next(res);
                },
                e => {
                    console.warn(e, `getDoc: ${path}`);
                },
                () => {
                    observer.complete();
                }
            );
        });
        return o;
    }

    buildQuery(query: any) {
        return (queryRef: any) => {
            let limit: number = 100;
            let result: any = queryRef;
            if (query) {
                query.forEach((item: IFirestoreQuery) => {
                    switch (item.name) {
                        case 'orderBy':
                            result = result.orderBy(item.args[0], item.args[1].toString());
                            break;
                        case 'where':
                            result = result.where(item.args[0], item.args[1], item.args[2]);
                            break;
                        case 'startAt':
                            result = result.startAt(item.args[0]);
                            break;
                        case 'startAfter':
                            result = result.startAfter(item.args[0]);
                            break;
                        case 'startBefore':
                            result = result.startBefore(item.args[0]);
                            break;
                        case 'endAt':
                            result = result.endAt(item.args[0]);
                            break;
                        case 'limit':
                            limit = item.args[0];
                            break;
                    }
                });
            }
            result = result.limit(limit)
            return result;
        }
    }
    async updateDocument(path: string, data: any): Promise<void> {
        try {
            await this.firestore.doc(path).update(data);
        } catch (error) {
            console.error("Error updating document:", error);
        }
    }

    async deleteDocument(documentPath: string): Promise<void> {
        try {
            await this.firestore.doc(documentPath).delete();
            console.log(`Document at ${documentPath} successfully deleted.`);
        } catch (error) {
            console.error(`Error deleting document at ${documentPath}:`, error);
            throw error;
        }
    }

}

export interface IFirestoreQuery {
    name: 'orderBy' | 'where' | 'limit' | 'startAfter' | 'startAt' | 'startBefore' | 'endAt';
    args: any[];
}
