// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    appName: 'axek',
    production: true,
    type: 'dev',
    dev: true,
    firebase: {
        apiKey: "AIzaSyBZu3-LOGqzL0yw96vWIWajOzPhIhsSuIs",
        authDomain: "axekindle-dev.firebaseapp.com",
        databaseURL: "https://axekindle-dev.firebaseio.com",
        projectId: "axekindle-dev",
        storageBucket: "axekindle-dev.appspot.com",
        messagingSenderId: "463094661392",
        appId: "1:463094661392:web:4e354513856ff052d62595",
        measurementId: "G-DRXHDLY4LH"
    },
    fnUrl: 'https://us-central1-axekindle-dev.cloudfunctions.net/api',
    domain: 'axekindle-dev.firebaseapp.com',
    admin_path: 'a',
    account_path: 'account',
    gaid: '',
    awid: '',
    override_alerts: true,
    override_uiblock: false,
    phone: '8005551212',
    auth: {
        email: true
    },
    default_client: {
        theme: {
            "dark": "#9c7d38",
            "light": "#ffffff",
            "accent": "#2e391b",
            "icon": "/assets/icons/64.png"
        },
        id: "yaynmqpb6iaxh1tdr7etg3",
        phone: "8008392008",
        email: "support@axeandkindle.com",
        name_key: "AXEK",
        public_facing_url: "https://axek-dev.web.app",
        config:  {
            contacts: {
                types: [
                    {name: 'Restaurant/Chef', description: 'Buying for a restaurant or food service company'},
                    {name: 'Hotel/Event Space', description: 'Buying for company in the hospitality industry'},
                    {name: 'Personal Use', description: 'I just like a good fire at home'},
                    {name: 'Other', description: 'I defy genre'}
                ]
            }
        }
    },
    stripe: 'pk_test_51M4Y6oHe8jPzOwgUzfYFsZxIkxqt4POav2FA85cF8PpqzJNEawy4NZlKg1YX5EWMCOFGFIp1CIEMbNh6KQN7B0Kz00v2kgC9JS',
    paypal: {
        account: 'sb-ftzog23511631@business.example.com',
        client_id: 'AfV8OUwCsR-_eZVLDtvBqu3lPQa6hua9u8fQyYmYm3psVlHlWJbDuOaDgccn9fBvtnUdQoTUM8fgMmge'
    },
    nxtProjectId: 'nxtpart-dev',
   algolia: {
      appId: '',
      searchKey: '',
   },
    default_roles:[],
    pageServicePrefix: ''
};
