"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CmsSettings = void 0;
const Base_1 = require("./Base");
const Common_1 = require("./Common");
const index_1 = require("../index");
class CmsSettings extends Base_1.Base {
    constructor(data, olMap) {
        super('settings', 9);
        this.theme = {};
        this.fonts = [];
        this.favicon = '';
        this.icon = '';
        this.logo = '';
        this.logo_dark = '';
        this.logo_light = '';
        this.images = {};
        this.status = {
            theme: false,
            fonts: false,
            favicon: false,
            icon: false,
            logo: false
        };
        this.deployments = {
            pending: 0,
            total: 0
        };
        (0, Common_1.setObjectProperties)(this, data, olMap, CmsSettings);
    }
}
exports.CmsSettings = CmsSettings;
index_1.olm.settings = (ref, map) => {
    return new CmsSettings(ref, map);
};
