"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThreadMessage = exports.REACTIONS = exports.EThreadMessageEvents = exports.EMedium = void 0;
const Common_1 = require("./Common");
const Thread_1 = require("./Thread");
const CloudFile_1 = require("./CloudFile");
const Contact_1 = require("./Contact");
const index_1 = require("../index");
var EMedium;
(function (EMedium) {
    EMedium["EMAIL"] = "email";
    EMedium["CHAT"] = "chat";
    EMedium["TEXT"] = "text";
    EMedium["NOTE"] = "note";
    EMedium["WEB"] = "web";
    EMedium["ARCHIVE"] = "archive";
    EMedium["NONE"] = "";
})(EMedium || (exports.EMedium = EMedium = {}));
var EThreadMessageEvents;
(function (EThreadMessageEvents) {
    EThreadMessageEvents["OPEN"] = "o";
    EThreadMessageEvents["CLICK"] = "c";
    EThreadMessageEvents["UNSUBSCRIBE"] = "u";
    EThreadMessageEvents["DELIVERED"] = "d";
    EThreadMessageEvents["SENT"] = "s";
    EThreadMessageEvents["BOUNCE"] = "b";
    EThreadMessageEvents["FAILED"] = "f";
})(EThreadMessageEvents || (exports.EThreadMessageEvents = EThreadMessageEvents = {}));
exports.REACTIONS = ['thumb-up']; //,'thumb-down','heart','check'];
class ThreadMessage extends Thread_1.Thread {
    get unassigned() {
        var _a, _b, _c;
        return (!((_a = this.agents) === null || _a === void 0 ? void 0 : _a.length) && !((_b = this.followers) === null || _b === void 0 ? void 0 : _b.length) && !((_c = this.roles) === null || _c === void 0 ? void 0 : _c.length)) ? true : false;
    }
    async loadAll(opts, stack, p) {
        opts.properties = (opts.properties !== undefined) ? opts.properties : Object.getOwnPropertyNames(this);
        opts.properties.splice(opts.properties.findIndex(p => p === 'activity'), 1);
        await super.loadAll(opts, stack, p);
    }
    constructor(data, olMap) {
        super(data, olMap, 'threadsmsgs', 32);
        this.unread = false;
        this.message = ''; // deprecate/move to msgs
        this.draft = false;
        this.results = {};
        this.domain = '';
        this.cc = [];
        this.bcc = [];
        this.signature = false;
        this.signatureHtml = '';
        this.replyingTo = null;
        this.forward = false;
        this.data = [];
        this.metadata = {};
        this.document_id = null;
        this.tRef = null;
        this.files = new index_1.ChildArray();
        this.reactions = {};
        this._m = ['date', 'creator', 'medium', 'subject', 'summary', 'inbound'];
        this._s = ['filters', 'medium', 'unread', 'inbound', 'active', 'cc', 'object', 'html', 'recipients', 'contact', 'creator', 'subject', 'summary', 'unassigned'];
        (0, Common_1.setObjectProperties)(this, data, olMap, ThreadMessage);
        if (this.object && olMap) {
            this.object = (0, index_1.loadObject)(this.object, { olm: olMap || index_1.olm });
            this.contact = (0, index_1.loadObject)(this.contact, { olm: olMap || index_1.olm });
            if (this.object && this.contact && !(this.object instanceof Contact_1.Contact)) {
                this.object.contact = this.contact;
            }
        }
    }
    async markRead() {
        if (this.id) {
            this.unread = false;
            await super.markRead();
            await this.save();
        }
    }
    async save(parent) {
        var _a, _b, _c, _d;
        if (!this.id) {
            this.setID();
        }
        if ((_a = this.object) === null || _a === void 0 ? void 0 : _a._docRef) {
            this.ref = (_b = this.object) === null || _b === void 0 ? void 0 : _b._docRef;
        }
        if ((_c = this.contact) === null || _c === void 0 ? void 0 : _c._docRef) {
            this.contactRef = this.contact._docRef;
        }
        if (!this.ref) {
            throw `Cannot Save! No reference set on message.`;
        }
        if (this.gmail_id) {
            this.third_party = this.gmail_id;
        }
        this.date = this.date || Date.now();
        if (parent === null || parent === void 0 ? void 0 : parent._docRef) {
            if (parent._type !== 'threads') {
                throw `Cannot Save! Must pass in thread as parent when saving a message.`;
            }
            this.tRef = parent._docRef;
            this._docRef = this.tRef.parent.parent.collection(this._type).doc(this.id);
        }
        if (!this._docRef) {
            throw `Cannot save ${this._type}! No document reference.`;
        }
        else if (!this.tRef) {
            throw `Cannot save ${this._type}! No thread reference.`;
        }
        else {
            this._new = !this._exists;
            this._docRef.set(this.toJSON());
            this._exists = true;
        }
        if ((_d = this.files) === null || _d === void 0 ? void 0 : _d.length) {
            await Promise.all(this.files.map((file) => {
                file = new CloudFile_1.CloudFile(file);
                if ((file === null || file === void 0 ? void 0 : file.id) && !file._exists) {
                    return file.save(this);
                }
            }));
        }
    }
}
exports.ThreadMessage = ThreadMessage;
index_1.olm.threadsmsgs = (ref, map) => {
    return new ThreadMessage(ref, map);
};
