import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, throwError} from 'rxjs';
import {catchError, take, takeUntil} from 'rxjs/operators';

import {FirebaseApp} from '@angular/fire/app';
import {
    connectFirestoreEmulator,
    getFirestore,
    collection,
    orderBy,
    where,
    limit,
    startAfter,
    query as fireQuery,
    doc,
    QueryConstraint,
    docSnapshots,
    collectionSnapshots,
    deleteDoc,
    setDoc,
    getDoc
} from '@angular/fire/firestore';
import {DocumentSnapshot} from 'rxfire/firestore/interfaces';

import {PageService} from '../../shared/_services/page.service';
import {ClientService} from '../../shared/_services/client.service';

// @ts-ignore
import {environment} from '@env/environment';
import {Base, ILoadAllOpts} from '@nxt/model-core';

@Injectable()
export class ConsumerFireService {
    firestore: any;

    loadAllOpts(olm: any): ILoadAllOpts {
        return {
            collection: collection,
            getDoc: getDoc,
            collectionSnapshots: collectionSnapshots,
            olm: olm,
            firestore: this.firestore
        }
    }

    constructor(
        private cSvc: ClientService,
        private pSvc: PageService,
        app: FirebaseApp
    ) {

        this.firestore = getFirestore(app);
        if (this.firestore && environment.type === 'local' && this.firestore?._settings?.host !== 'localhost:8080') {
            connectFirestoreEmulator(this.firestore, 'localhost', 8080);
        }

    }

    watchDoc(path: string, destroy$?: ReplaySubject<boolean>, persistent?: boolean): Observable<DocumentSnapshot<any>> {
        let ref = docSnapshots<any>(doc(this.firestore, path));
        if (destroy$) {
            ref = ref.pipe(takeUntil(destroy$))
        }
        if (!persistent) {
            ref = ref.pipe(takeUntil(this.pSvc.nav$))
        }
        if (this.cSvc.u$.getValue()) {
            ref = ref.pipe(takeUntil(this.cSvc.signedOut));
        }
        ref = ref.pipe(catchError((error: any) => {
            // Handle the error here
            console.error(path, error);
            // Optionally, re-throw the error or return a default value
            return throwError('Error in watchDoc');
        }))
        return ref;
    }

    watchColl(
        path: string,
        query?: IFirestoreQuery[],
        destroy$?: ReplaySubject<boolean>,
        persistent?: boolean
    ): Observable<any[]> {
        let coll: any = collection(this.firestore, path);
        let q: QueryConstraint[] = this.buildQuery(query);
        let ref = collectionSnapshots(fireQuery(coll, ...q));
        if (destroy$) {
            ref = ref.pipe(takeUntil(destroy$))
        }
        if (!persistent) {
            ref = ref.pipe(takeUntil(this.pSvc.nav$))
        }
        if (this.cSvc.u$.getValue()) {
            ref = ref.pipe(takeUntil(this.cSvc.signedOut));
        }
        ref = ref.pipe(catchError((error: any) => {
            // Handle the error here
            console.error(path, error);
            // Optionally, re-throw the error or return a default value
            return throwError('Error in watchColl');
        }))
        return ref;
    }

    getDocRef(path: string): any {
        return doc(this.firestore, path);
    }

    getDoc(path: string): Observable<any> {
        return docSnapshots<any>(doc(this.firestore, path))
            .pipe(catchError((error: any) => {
                // Handle the error here
                console.error(path, error);
                // Optionally, re-throw the error or return a default value
                return throwError('Error in getDoc');
            }))
            .pipe(take(1))
    }

    setDoc(obj: Base) {
        if (!obj._docRef) {
            throw `Cannot save object without docRef.`
        }
        return setDoc(obj._docRef, obj.toJSON()).catch(e => {
            console.error(obj._docRef.path, e);
        });
    }

    deleteDoc(obj: Base) {
        if (!obj._docRef) {
            throw `Cannot delete object without docRef.`
        }
        return deleteDoc(obj._docRef).catch(e => {
            console.error(obj._docRef.path, e);
        });
    }

    set(path: string, obj: any) {
        let ref: any = doc(this.firestore, path);
        return setDoc(ref, obj).catch(e => {
            console.error(obj._docRef.path, e);
        });
    }

    getColl(path: string, query?: IFirestoreQuery[]): Observable<any[]> {
        let ref: any = collection(this.firestore, path);
        let q: QueryConstraint[] = this.buildQuery(query);
        return collectionSnapshots(fireQuery(ref, ...q))
            .pipe(catchError((error: any) => {
                // Handle the error here
                console.error(path, error);
                // Optionally, re-throw the error or return a default value
                return throwError('Error in getDoc');
            }))
            .pipe(take(1));
    }

    buildQuery(query: any): QueryConstraint[] {
        let result: QueryConstraint[] = [];
        if (query?.length) {
            let numLimit: number = 100;
            query.forEach((item: IFirestoreQuery) => {
                switch (item.name) {
                    case 'orderBy':
                        result.push(orderBy(item.args[0], item.args[1].toString()));
                        break;
                    case 'where':
                        result.push(where(item.args[0], item.args[1], item.args[2]));
                        break;
                    case 'startAfter':
                        result.push(startAfter(item.args[0]));
                        break;
                    case 'limit':
                        numLimit = item.args[0];
                        break;
                }
            });
            result.push(limit(numLimit));
        }
        return result;
    }

}

export interface IFirestoreQuery {
    name: 'orderBy' | 'where' | 'limit' | 'startAfter' | 'startBefore' | 'startAt';
    args: any[];
}
