"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Footer = exports.Header = exports.Page = void 0;
const Common_1 = require("./Common");
const Base_1 = require("./Base");
const index_1 = require("../index");
class Page extends Base_1.Base {
    constructor(data, olMap, type, idLen) {
        super(type || 'pages', idLen || 27);
        this.url = '';
        this.name = '';
        this.urls = [];
        this.sitemap = null;
        this.created = null;
        this.edited = null;
        this.published = null;
        this.exclude = false;
        this.meta = {};
        this.content = {};
        this.footer_id = '';
        this.footer = {};
        this.header_id = '';
        this.header = {};
        this.subheader_id = '';
        this.subheader = {};
        this.branding = {};
        (0, Common_1.setObjectProperties)(this, data, olMap, Page);
    }
    toJSON() {
        let r = super.toJSON();
        if (this.jsonld) {
            r.jsonld = JSON.parse(JSON.stringify(this.jsonld));
        }
        else {
            delete r.jsonld;
        }
        return r;
    }
    toDeploymentJSON() {
        let r = this.toJSON();
        delete r.content;
        return r;
    }
    async save(parent) {
        if (!this.id) {
            this.setID();
        }
        if (parent && parent._docRef) {
            this._docRef = parent._docRef.collection('cms').doc('root').collection(this._type).doc(this.id);
        }
        if (!this._docRef) {
            throw `Cannot save! No document reference.`;
        }
        else {
            if (!this.created) {
                this.created = Date.now();
            }
            return super.save();
        }
    }
}
exports.Page = Page;
class Header extends Page {
    constructor(data, olMap) {
        super(data, olMap, 'headers', 7);
        (0, Common_1.setObjectProperties)(this, data, olMap, Header);
    }
}
exports.Header = Header;
class Footer extends Page {
    constructor(data, olMap) {
        super(data, olMap, 'footers', 7);
        (0, Common_1.setObjectProperties)(this, data, olMap, Footer);
    }
}
exports.Footer = Footer;
index_1.olm.pages = (ref, map) => {
    return new Page(ref, map);
};
index_1.olm.headers = (ref, map) => {
    return new Header(ref, map);
};
index_1.olm.footers = (ref, map) => {
    return new Footer(ref, map);
};
